/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import slugify from 'slugify'
import { PlayCircleIcon, TalkIcon, LinkedinIcon } from '../Icons'

const TakeawaysParagraph = styled.p`
  margin: 0 0 10px 0 !important;
`

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    // [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <TakeawaysParagraph>{children}</TakeawaysParagraph>
    ),
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
    [INLINES.HYPERLINK]: (node) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {node.content[0].value}
      </a>
    ),
  },
}

const BoldText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin: 0;
  }
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #5a55ab;
    background: #efeef7;
    border-radius: 100px;
    padding: 2px 8px;
  }
`

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IconContainer = styled.a`
  display: flex;
  align-items: flex-start;
  p {
    color: #556685;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0px 8px 0px 0px;
  }
`

const Shorts = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 850px;
  @media (min-width: 1200px) {
    max-width: 840px;
  }
`

const TeaserImages = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 12px;
  flex-wrap: wrap;
  justify-content: ${({ length }) => (length > 4 ? 'space-between' : 'normal')};
`

const TeaserImage = styled(Link)`
  border-radius: 4px;
  background-image: url(${(props) => props.teaser});
  background-size: 150px 253px;
  background-repeat: no-repeat;
  height: 253px;
  max-width: 140px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media (min-width: 768px) {
    max-width: 170px;
  }
  @media (min-width: 1200px) {
    /* max-width: 170px; */
  }
  p {
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    margin: 0px;
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
`

const Player = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 4px;
  margin: 24px 0px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

const Takeaways = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  background: #efeef7;
  padding: 14px;
  margin: 16px 0 14px;
  p {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0px;
  }
`

const Video = ({ youtubeEmbed, videoTitle }) => (
  <Player>
    <iframe
      className="video"
      src={youtubeEmbed}
      title={videoTitle}
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </Player>
)

const SingleShort = ({ short, interview }) => {
  const link = `https://www.linkedin.com/sharing/share-offsite/?url=https://innential.com/virtual-library/shorts/${slugify(
    short.title
  ).toLowerCase()}`

  const richDescription = short.takeawaysRich
    ? renderRichText(short.takeawaysRich, options)
    : null

  return (
    <Shorts>
      <TopInfo>
        <BoldText>
          <p>{short.title}</p>
        </BoldText>
        <IconContainer href={link} target="_blank" rel="noreferrer">
          <p>Share</p>
          <LinkedinIcon fill="#556685" />
        </IconContainer>
      </TopInfo>
      <Video youtubeEmbed={short.youtubeEmbed} videoTitle={short.title} />
      <BoldText>
        <p>Key Takeaways</p>
      </BoldText>
      <Takeaways>
        <span>
          <TalkIcon fill="#5A55AB" />
        </span>
        {richDescription ? (
          <div>{richDescription}</div>
        ) : (
          <p>{short.takeaways.takeaways}</p>
        )}
      </Takeaways>
      <BoldText>
        <p>Interview Shorts</p>
      </BoldText>
      <TeaserImages wrap>
        {interview.shorts.map((question) => (
          <TeaserImage
            teaser={question.teaserImage?.file?.url}
            to={`/virtual-library/shorts/${slugify(
              question.title
            ).toLowerCase()}`}
          >
            <PlayCircleIcon fill="#ffffff" />
            <p>{question.title}</p>
          </TeaserImage>
        ))}
      </TeaserImages>
    </Shorts>
  )
}

export default SingleShort
