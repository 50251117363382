/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import YoutubeIcon from '../../../assets/youtube-icon.svg'

const Card = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 13px;
  min-height: 100%;
`

const CardImg = styled.img`
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  max-height: 125px;
`

const CardTitle = styled.p`
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-align: left;
  margin: 0;
`

const CardTitleLink = styled(Link)`
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-align: left;
  margin: 0;
`

const CardDescription = styled.p`
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #152540;
  margin: 0;
`

const CardLink = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    color: #000000;
    font-family: Poppins;
    font-style: 400;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.008em;
  }
`

const InterviewItem = ({ interview, style, isInterview, titleLink }) => {
  const CardTitleNode = titleLink ? CardTitleLink : CardTitle
  return (
    <Card style={{ ...style }}>
      <CardImg src={interview.bannerImage.file.url} alt={interview.title} />
      <CardTitleNode to={titleLink}>{interview.title}</CardTitleNode>
      <CardDescription>{interview.description}</CardDescription>
      {isInterview && (
        <CardLink href={interview.linkToYoutube} target="_blank" rel="noopener">
          <p>See Complete Playlist</p>
          <img src={YoutubeIcon} alt="youtube" />
        </CardLink>
      )}
    </Card>
  )
}
export default InterviewItem
