/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import slugify from 'slugify'
import InterviewItem from './InterviewItem'
import InterviewPathBanner from './InterviewPathBanner'
import ParticipantCard from './ParticipantCard'
import SingleShort from './SingleShort'

const Container = styled.div`
  display: flex;
  gap: 36px;
  flex-direction: column;
  padding: 0px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: auto;
  width: 100%;
  /* @media (min-width: 768px) {
    flex-direction: row;
  } */
  @media (min-width: 1200px) {
    flex-direction: column;
    max-width: 400px;
  }
`

const InfoNoBanner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: auto;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    flex-direction: column;
    max-width: 400px;
  }
`

const ParticipantsWrapper = styled.section`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

const InterviewItemWrapper = styled(Link)``

const Participants = styled.div`
  display: flex;
  gap: 30px;
  flex: 1;
`

const BoldText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin: 0;
  }
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #5a55ab;
    background: #efeef7;
    border-radius: 100px;
    padding: 2px 8px;
  }
`

// eslint-disable-next-line react/prop-types
const InterviewShorts = ({ data, interview }) => (
  <Container>
    <Info>
      <InfoNoBanner>
        {/* <InterviewItemWrapper
          to={`/../virtual-library/interviews/${slugify(
            interview.title
          ).toLowerCase()}`}
        > */}
        <div>
          <InterviewItem
            interview={interview}
            isInterview
            titleLink={`/virtual-library/interviews/${slugify(
              interview.title
            ).toLowerCase()}`}
          />
        </div>
        {/* </InterviewItemWrapper> */}
        <ParticipantsWrapper>
          <BoldText>
            <p>Participants</p>
            <span>{interview.participants.length}</span>
          </BoldText>

          <Participants>
            {interview.participants.map((participant) => (
              <ParticipantCard key={participant.id} participant={participant} />
            ))}
          </Participants>
        </ParticipantsWrapper>
      </InfoNoBanner>
      <InterviewPathBanner
        large={false}
        pictureUrl={interview.interviewLearningPathImage.file.url}
        title={interview.interviewLearningPath}
        description={
          interview.interviewLearningPathShortDescription
            .interviewLearningPathShortDescription
        }
      />
    </Info>
    <SingleShort short={data} interview={interview} fullInterview />
  </Container>
)

export default InterviewShorts
