/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import slugify from 'slugify'
import LearningPathItem from './LearningPathItem'
import LibraryItem from './LibraryItem'
import device from '../../utils/mq'
import EventItem from './EventItem'
import InterviewItem from './interview/InterviewItem'
import Interview from './interview/Interview'
import InterviewShorts from './interview/InterviewShorts'
import { PlayCircleIcon } from './Icons'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 285px)')};
    margin-left: ${({ fullWidth }) => (fullWidth ? '0' : '30px')};
  }
`

const ListHeader = styled.div`
  width: 100%;
  display: inline;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
`

const CommunityLink = styled.a`
  opacity: 1;

  :hover {
    opacity: 0.7;
  }
`

const CommunityText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #5a55ab;
  margin-left: 16px;
`

const ListBody = styled.div`
  display: flex;
  flex-direction: ${({ largeGutter }) => (largeGutter ? 'column' : 'row')};
  gap: 3vw;
  overflow-x: scroll;
  margin: ${({ largeGutter }) =>
    largeGutter ? '20px 0' : '20px -15px 25px 0'};

  @media ${device.tablet} {
    flex-direction: row;
    gap: 0;
    overflow-x: hidden;
    flex-wrap: wrap;
    margin: ${({ largeGutter }) =>
      largeGutter ? '20px -15px 25px' : '20px -4px 25px'};
    > * {
      margin: ${({ largeGutter }) => (largeGutter ? '15px' : '4px')};
    }
  }
`

const ListBodyLearning = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 25px 0 ' : ' 20px -15px 25px;
  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${({ largeGutter }) =>
      largeGutter ? '20px -15px 25px' : '20px -4px 25px'};
    > * {
      margin: ${({ largeGutter }) => (largeGutter ? '15px' : '4px')};
    }
  }
`

const LearnWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media ${device.tablet} {
    width: 22.5%;
    min-width: 240px;
    margin-bottom: 0px;
  }
`

const LibraryWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media ${device.tablet} {
    min-width: 140px;
    width: ${({ event }) => (event ? '30.5%' : '19%')};
    margin-bottom: 8px;
  }
`

const Shorts = styled.div`
  /* @media (min-width: 768px) {
    margin-left: 55px;
  } */
`

const InterviewsWrapper = styled.div`
  margin-top: 35px;
  @media (min-width: 740px) {
    margin-top: 0px;
  }
`

const Interviews = styled.div`
  display: flex;
  margin: 30px 0px;
  gap: 30px;
  flex-direction: column;
  @media (min-width: 740px) {
    flex-direction: row;
    gap: 30px;
  }
`

const TeaserImages = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 12px;
  flex-wrap: wrap;
`

const TeaserImage = styled(Link)`
  border-radius: 4px;
  background-image: url(${(props) => props.teaser});
  background-size: cover;
  background-repeat: no-repeat;
  height: 253px;
  width: 100%;
  max-width: 150px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  p {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
`

const InterviewLink = styled(Link)``

const Community = ({ children, communityUrl }) => {
  if (communityUrl) {
    return (
      <CommunityLink
        target="_blank"
        rel="noopener noreferrer"
        href={communityUrl}
      >
        {children}
      </CommunityLink>
    )
  }
  return children
}

const ContentsList = ({
  data,
  learningPaths,
  tab,
  safeWindow,
  interviews,
  // children,
  singleInterview,
  singleShort,
  interview,
}) => {
  const trackItemView = ({ type, url, action = 'item_view' }) => {
    if (typeof safeWindow.gtag === 'function') {
      safeWindow.gtag('event', action, {
        event_category: type,
        event_label: url,
      })
    }
  }

  // const [bannerClicked, setBannerClicked] = useState(clickedAgain)
  return (
    <Wrapper
      fullWidth={tab === 'EVENT' || tab === 'LEARN' || tab === 'INTERVIEWS'}
    >
      {data.map((category, i) => (
        <>
          <ListHeader>
            <Title>{category.name}</Title>
            {category.community && (
              <Community communityUrl={category.communityUrl}>
                <CommunityText>curated by {category.community}</CommunityText>
              </Community>
            )}
          </ListHeader>
          <ListBody largeGutter={category.type === 'EVENT'}>
            {category.items?.map((item) => (
              <LibraryWrapper event={category.type === 'EVENT'}>
                {category.type === 'EVENT' ? (
                  <EventItem
                    key={item.title}
                    data={item}
                    trackItemView={trackItemView}
                  />
                ) : (
                  <LibraryItem
                    key={item.title}
                    data={item}
                    type={category.type}
                    community={
                      category.community
                        ? {
                            name: category.community,
                            url: category.communityUrl,
                          }
                        : null
                    }
                    trackItemView={trackItemView}
                  />
                )}
              </LibraryWrapper>
            ))}
          </ListBody>
        </>
      ))}

      {tab === 'LEARN' && (
        <>
          <ListHeader>
            <Title>
              Carefully selected content in easy to follow learning paths
            </Title>
          </ListHeader>
          <ListBodyLearning largeGutter>
            {learningPaths.map((item) => (
              <LearnWrapper>
                <LearningPathItem
                  key={item.title}
                  data={item}
                  trackItemView={trackItemView}
                />
              </LearnWrapper>
            ))}
          </ListBodyLearning>
        </>
      )}
      {tab === 'INTERVIEWS' && (
        <InterviewsWrapper>
          {singleInterview && <Interview data={singleInterview} />}
          {singleShort && (
            <InterviewShorts data={singleShort} interview={interview} />
          )}
          {!singleInterview && !singleShort && (
            <>
              <ListHeader>
                <Title>People Development Library interviews</Title>
                <Interviews>
                  {interviews.map((inter) => (
                    <InterviewLink
                      key={inter.id}
                      to={`/virtual-library/interviews/${slugify(
                        inter.title
                      ).toLowerCase()}`}
                    >
                      <InterviewItem
                        interview={inter}
                        // style={{ margin: '30px 0px' }}
                      />
                    </InterviewLink>
                  ))}
                </Interviews>
              </ListHeader>
              <div style={{ display: 'flex' }}>
                {/* filters */}
                {/* <div>{children}</div> */}
                <Shorts>
                  <Title>Interview Shorts</Title>
                  <TeaserImages>
                    {interviews[0].shorts.map((short) => (
                      <TeaserImage
                        teaser={short.teaserImage?.file?.url}
                        to={`/virtual-library/shorts/${slugify(
                          short.title
                        ).toLowerCase()}`}
                      >
                        <PlayCircleIcon fill="#ffffff" />
                        <p>{short.title}</p>
                      </TeaserImage>
                    ))}
                  </TeaserImages>
                </Shorts>
              </div>
            </>
          )}
        </InterviewsWrapper>
      )}
    </Wrapper>
  )
}

export default ContentsList
