/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import styled from 'styled-components'
import { CloseIcon } from './Icons'
import Button from '../Button'
import device from '../../utils/mq'
import externalLink from '../../assets/icons/external-link.svg'

const DialogWrapper = styled.div`
  width: 100%;
  height: 103%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    /* align-items: flex-start; */
    height: 103%;
    overflow: scroll;
    z-index: 23;
  }
`

const Dialog = styled.div`
  width: 90%;
  background: white;
  margin: 150px 20px;
  padding: 20px 20px 30px 20px;
  box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.1),
    0px 32px 64px rgba(0, 8, 32, 0.12);
  border-radius: 4px;

  @media ${device.tablet} {
    width: 60%;
    padding: 55px;
  }
`

const CloseIconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -18px;
  margin-left: 10px;

  @media ${device.tablet} {
    margin-top: -55px;
    margin-left: 55px;
  }
`

const CloseIconButton = styled.div`
  padding: 15px;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  display: flex;
`

const ImageWrapper = styled.div`
  width: 0%;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    width: 40%;
    margin-right: 40px;
  }
`

const Image = styled.img`
  max-height: 320px;
  display: none;
  box-shadow: 0px 16px 32px rgba(0, 8, 32, 0.1), 0px 1px 4px rgba(0, 8, 32, 0.1);
  max-width: 100%;

  @media ${device.tablet} {
    display: block;
    margin: 0 auto;
  }
`

const Content = styled.div`
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: 60%;
  }
`

const CommunityLink = styled.a`
  opacity: 1;
  color: #5a55ab;
  margin-bottom: 10px;

  :hover {
    opacity: 0.7;
  }
`

const CommunityText = styled.span`
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  margin-right: 4px;
`

const Category = styled.p`
  color: #8494b2;
  font-size: 16px;
  margin: 0;
`
const Author = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 6px 0;
`
const Title = styled.p`
  font-size: 24px;
  font-weight: 900;
  margin: 6px 0;
`
const Description = styled.p`
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  margin: 6px 0;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  @media ${device.tablet} {
    justify-content: flex-end;
  }
`

const Price = styled.p`
  color: #128945;
  font-weight: bold;
  font-size: 14px;
  margin: 0;

  @media ${device.tablet} {
    margin: 0 24px;
  }
`

const Community = ({ children, communityUrl }) => {
  if (communityUrl) {
    return (
      <CommunityLink
        target="_blank"
        rel="noopener noreferrer"
        href={communityUrl}
      >
        {children}
      </CommunityLink>
    )
  }
  return children
}

const LibraryDialog = ({
  data,
  open,
  setOpen,
  community,
  trackItemView,
  type,
}) => {
  const {
    source,
    description,
    author,
    title,
    thumbnail: { file },
    price,
    url,
    richTextDescription,
  } = data

  const ref = useRef(null)

  const handleKeypress = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleKeypress, true)
    return () => {
      document.removeEventListener('click', handleKeypress, true)
    }
  })

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
      [MARKS.CODE]: (text) => <code>{text}</code>,
    },
    renderNode: {
      // [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.HR]: () => <hr />,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
    },
  }

  const regularDescription =
    description?.description.length > 500
      ? `${description?.description.slice(0, 500)}...`
      : description?.description

  return (
    <>
      {open && (
        <DialogWrapper>
          <Dialog ref={ref}>
            <CloseIconWrapper>
              <CloseIconButton onClick={() => setOpen(false)}>
                <CloseIcon fill="#152540" />
              </CloseIconButton>
            </CloseIconWrapper>
            <ContentWrapper>
              <ImageWrapper>
                <Image src={file.url} />
              </ImageWrapper>
              <Content>
                {community && (
                  <Community communityUrl={community.url}>
                    <CommunityText>
                      Join “{community.name}” on LinkedIn
                    </CommunityText>
                    <img src={externalLink} alt="icon" />
                  </Community>
                )}
                {source && <Category>{source.name}</Category>}
                <Author>{author}</Author>
                <Title>{title}</Title>
                <Description>
                  {richTextDescription
                    ? renderRichText(richTextDescription, options)
                    : regularDescription}
                </Description>
                <Footer>
                  <Price>{price > 0 ? `€${price.toFixed(2)}` : 'FREE'}</Price>
                  <Button
                    link={url}
                    target="_blank"
                    title={`Go to ${source ? source.name : 'item'}`}
                    primary
                    style={{
                      height: '34px',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      padding: '0 22px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() =>
                      trackItemView({ type, url, action: 'item_click' })
                    }
                  />
                </Footer>
              </Content>
            </ContentWrapper>
          </Dialog>
        </DialogWrapper>
      )}
    </>
  )
}

export default LibraryDialog
