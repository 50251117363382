/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Link } from 'gatsby'
import slugify from 'slugify'
import styled from 'styled-components'
import { CompassIcon, BellIcon, BookIcon, RightUpIcon } from '../Icons'

const Banner = styled.div`
  background: #5a55ab;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: ${({ large }) => (large ? '12px 20px 24px' : '18px 24px 24px')};
  margin: ${({ large }) => (large ? '26px 0px' : '8px 0px')};
  gap: 10px;
  /* max-width: 850px; */
  @media (min-width: 490px) {
    flex-direction: row;
    gap: 42px;
    padding: ${({ large }) =>
      large ? '16px 16px 16px 26px' : '8px 16px 16px 26px'};
  }
  @media (min-width: 768px) {
    max-width: ${({ large }) => (large ? 'none' : '850px')};
  }
  @media (min-width: 1200px) {
    gap: ${({ large }) => (large ? '26px' : '0px')};
    flex-direction: ${({ large }) => (large ? 'row' : 'column')};
    padding: ${({ large }) =>
      large ? '16px 16px 16px 26px' : '24px 24px 28px'};
  }
`

const BannerInfo = styled.div`
  flex: 40%;
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0px 8px;
    @media (min-width: 490px) {
      font-size: 16px;
      line-height: 26px;
      margin: 10px 0px;
    }
  }
`
const BannerInfoDetails = styled.div`
  display: flex;
  gap: 10px;
  padding: 5px 0px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    @media (min-width: 490px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
`

const BannerPath = styled.div`
  background: #ffffff;
  border-radius: 4px;
  flex: 60%;
  @media (min-width: 490px) {
    margin-top: ${({ large }) => (large ? '0px' : '12px')};
  }
`

const BannerPathImage = styled.div`
  position: relative;
  /* max-width: 460px; */
  max-height: ${({ large }) => (large ? '75px' : '100px')};
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: ${({ large }) => (large ? '84px' : '100px')};
    border-radius: 4px 4px 0px 0px;
  }
`

const BannerPathLabel = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  background: #4acf89;
  border-radius: 4px;
  padding: 4px 6px;
  position: absolute;
  right: 10px;
  top: 10px;
`

const BannerPathInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  padding: ${({ large }) => (large ? '14px 24px' : '11px')};
  @media (min-width: 768px) {
  }
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin: 0px 0px 8px;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8494b2;
    padding-bottom: 14px;
  }
`
const Button = styled(Link)`
  font-family: Poppins;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8%;
  border-radius: 26px;
  border: 2px solid;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  background-color: transparent;
  border-color: #5a55ab;
  width: fit-content;
  padding: 4px 14px;
  display: flex;
  align-items: center;
  &:hover,
  active,
  focuse {
    color: #fff;
    background-color: #5a55ab;
    border-color: #5a55ab;
  }
`

const Icon = styled.div`
  display: flex;
`

const InterviewPathBanner = ({ pictureUrl, title, description, large }) => {
  const safeWindow = typeof window !== 'undefined' ? window : {}

  const pathname = safeWindow?.location?.pathname

  const [iconColor, setIconColor] = useState('#5A55AB')

  return (
    <Banner large={large}>
      <BannerInfo>
        <p>Follow this interview using Innential Path </p>
        <BannerInfoDetails>
          <Icon>
            <CompassIcon fill="#ffffff" />
          </Icon>
          <span>Easy to follow</span>
        </BannerInfoDetails>
        <BannerInfoDetails>
          <Icon>
            <BellIcon fill="#ffffff" />
          </Icon>
          <span>Smart reminders</span>
        </BannerInfoDetails>
        <BannerInfoDetails>
          <Icon>
            <BookIcon fill="#ffffff" />
          </Icon>
          <span>Supporting resources (Books, Videos etc.)</span>
        </BannerInfoDetails>
      </BannerInfo>
      <BannerPath large={large}>
        <BannerPathImage>
          <img src={pictureUrl} alt={title} />
          <BannerPathLabel>Learning Path</BannerPathLabel>
        </BannerPathImage>
        <BannerPathInfo>
          <p>{title}</p>
          <span>{description}</span>
          {/* {large && ( */}
          <Button
            to={`/learning-paths/${slugify(
              title
            ).toLowerCase()}?ref=vlin&backto=${pathname}`}
            onMouseEnter={() => setIconColor('#ffffff')}
            onMouseLeave={() => setIconColor('#5A55AB')}
          >
            Enter Path
            <RightUpIcon fill={iconColor} />
          </Button>
          {/* )} */}
        </BannerPathInfo>
      </BannerPath>
    </Banner>
  )
}

export default InterviewPathBanner
