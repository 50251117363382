/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import clock2 from '../../assets/icons/clock2.svg'
import LibraryDialog from './LibraryDialog'
import device from '../../utils/mq'

const Container = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  width: 50vw;

  a {
    @media ${device.tablet} {
      display: none;
    }
  }

  &:hover {
    box-shadow: 0px 4px 8px rgb(0 8 32 / 6%), 0px 1px 4px rgb(0 8 32 / 8%);
    a {
      display: unset;
    }
    .footer {
      justify-content: flex-start;
    }
    .duration {
      width: 55%;
    }
  }

  @media ${device.tablet} {
    width: 100%;
  }
`

const Content = styled.div`
  padding: 0 10px 12px 10px;
`

const Footer = styled.div`
  padding: 7px 12px;
  border-top: 1px solid #dae1ed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #556685;
  transition: all 0.5s ease;
  min-height: 49px;

  svg {
    margin-right: 6px;
  }
`

const Duration = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media ${device.tablet} {
    justify-content: center;
  }
`

const Title = styled.h2`
  margin: 0px;
  font-weight: 900;
  font-size: 12px;
  line-height: 18px;
  color: #152540;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Group = styled.p`
  margin: 0;
  margin-top: 10px;
  font-weight: 500;
  font-size: 10px;
  color: #556685;
  min-height: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 4px rgba(0, 8, 32, 0.12))
    drop-shadow(0px 4px 8px rgba(0, 8, 32, 0.08));
`

const LibraryItem = ({ data, trackItemView, type, community }) => {
  const {
    title,
    durationText,
    author,
    url,
    thumbnail: { file },
  } = data

  const [open, setOpen] = useState(false)

  return (
    <>
      <Container
        onClick={() => {
          trackItemView({ type, url })
          setOpen(true)
        }}
      >
        <div
          style={{
            width: 'calc(100% - 24px)',
            margin: '16px 12px 12px 12px',
            height: '125px',
            display: 'flex',
          }}
        >
          <Img src={file.url} alt={title} />
        </div>
        <Content>
          <Title>{title}</Title>
          <Group>{author}</Group>
        </Content>
        <Footer className="footer">
          <Button
            title={(type || 'Details').toLowerCase()}
            primary={false}
            secondary
            style={{
              fontWeight: 'bold',
              fontSize: '10px',
              padding: '8px 12px',
              border: '1px solid',
              textTransform: 'capitalize',
              marginRight: '10px',
            }}
          />
          {durationText && (
            <Duration className="duration">
              <img src={clock2} alt="icon" />
              <p style={{ marginLeft: 6 }}>{durationText}</p>
            </Duration>
          )}
        </Footer>
      </Container>
      <LibraryDialog
        open={open}
        setOpen={(value) => setOpen(value)}
        data={data}
        community={community}
        type={type}
        trackItemView={trackItemView}
      />
    </>
  )
}

export default LibraryItem
