/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import slugify from 'slugify'
import { Link } from 'gatsby'
import clock1 from '../../assets/icons/clock1.svg'

const Container = styled.div`
  overflow: hidden;
  width: 100%;
  min-height: 450px;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  &:hover {
    box-shadow: 0px 4px 8px rgb(0 8 32 / 6%), 0px 1px 4px rgb(0 8 32 / 8%);
  }
`

const Content = styled.div`
  min-height: 210px;
  padding: 15px;
`

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #dae1ed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  color: #556685;
  img {
    margin-right: 6px;
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Title = styled.h2`
  margin: 0px;
  margin-top: 16px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #152540;
`

const Descripption = styled.p`
  margin: 0;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #556685;
  text-overflow: ellipsis;
  display: block;
`

const Tag = styled.span`
  width: auto;
  margin: 3px;
  font-weight: normal;
  font-size: 12px;
  color: #67c23a;
  background-color: rgba(103, 194, 58, 0.1);
  padding: 5px 10px;
  border-radius: 2px;
`

const LearningPathItem = ({ data, trackItemView }) => {
  const { name, duration, abstract, bannerSrc } = data

  const pathname = slugify(name).toLowerCase()

  return (
    <Container onClick={() => trackItemView({ type: 'PATH', url: name })}>
      <Link
        to={`/learning-paths/${pathname}?ref=vllp&backto=/virtual-library/paths`}
      >
        <img
          src={bannerSrc}
          alt="img"
          style={{ height: `170px`, width: '100%', objectFit: `cover` }}
        />
        <Content>
          <Tags>
            <Tag>LEARNING PATH</Tag>
          </Tags>
          <Title>{name}</Title>
          <Descripption>{abstract}</Descripption>
        </Content>
        <Footer>
          <img src={clock1} alt="icon" />
          {duration}
        </Footer>
      </Link>
    </Container>
  )
}

export default LearningPathItem
