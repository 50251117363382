/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
// import Button from '../Button'
// import shareIcon from '../../assets/left-icon.png'

const Button = styled.div`
  display: inline-block;
  width: auto;
  height: auto;
  padding: 8px 12px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.25px;
  border-radius: 26px;
  border: 1px solid;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

  color: ${(props) => props.theme.colors.brandPrimary};
  background-color: #fff;
  border-color: ${(props) => `${props.theme.colors.brandPrimary} !important`};

  &:hover,
  active,
  focus {
    color: #fff;
    background-color: ${(props) => props.theme.colors.brandPrimary};
    border-color: ${(props) => props.theme.colors.brandPrimary};
  }
`

const Container = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  position: relative;

  .footer {
    justify-content: space-between;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgb(0 8 32 / 6%), 0px 1px 4px rgb(0 8 32 / 8%);
    .duration {
      width: 55%;
    }
  }
`

const Content = styled.div`
  padding: 0 16px 12px 16px;
`

const Footer = styled.div`
  padding: 7px 12px;
  border-top: 1px solid #dae1ed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  color: #556685;
  transition: all 0.5s ease;
  min-height: 49px;

  svg {
    margin-right: 6px;
  }
`

const DateBlock = styled.div`
  height: 44px;
  width: 59px;
  box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.2);
  border-radius: 4px;
  text-align: center;
  background-color: #fff;
  position: absolute;
  top: 115px;
  left: 16px;

  div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: #29a399;
    line-height: 22px;
  }
  div:last-child {
    font-size: 12px;
    font-weight: normal;
    color: #29a399;
    line-height: 18px;
  }
`

const Title = styled.h2`
  margin: 25px 0 0 0;
  font-weight: 900;
  font-size: 16px;
  line-height: 26px;
  color: #000;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 4px rgba(0, 8, 32, 0.12))
    drop-shadow(0px 4px 8px rgba(0, 8, 32, 0.08));
`

const Info = styled.div`
  color: #556685;
  font-size: 12px;
  line-height: 18px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

// const ShareButton = styled.span`
//   display: inline-flex;
//   vertical-align: middle;
//   align-items: center;
//   font-size: 10px;
//   cursor: pointer;
//   opacity: 1;

//   :hover {
//     opacity: 0.7;
//   }

//   img {
//     margin-right: 4px;
//   }
// `

// const Price = styled.p`
//   color: #128945;
//   font-weight: bold;
//   font-size: 14px;
// `

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
]

const EventItem = ({ data, trackItemView }) => {
  const {
    title,
    location,
    // price = 0,
    type,
    community,
    url,
    exactDate,
    endDate,
    thumbnail: { file },
  } = data

  const DateBox = ({ start, end }) => {
    const date = new Date(start)
    const endDay = end ? new Date(end).getDate() : ''
    const day = date.getDate()
    const month = months[date.getMonth()]

    return (
      <DateBlock>
        <div>
          {day}
          {endDay ? ` - ${endDay}` : ''}
        </div>{' '}
        <div>{month}</div>
      </DateBlock>
    )
  }

  return (
    <a
      onClick={() => trackItemView({ type: 'EVENT', url })}
      href={url}
      rel="noreferrer noopener"
      target="_blank"
    >
      <Container>
        <div
          style={{
            width: 'calc(100% - 24px)',
            margin: '16px 12px 12px 12px',
            height: '125px',
            display: 'flex',
          }}
        >
          <Img src={file?.url} alt={title} />
        </div>
        <DateBox start={exactDate} end={endDate} />
        <Content>
          <Title>{title}</Title>
          <Info>
            {location} • {type}
            {community ? ` • ${community}` : ''}
          </Info>
        </Content>
        <Footer className="footer">
          <div>
            <Button
              title="See event"
              primary={false}
              secondary
              style={{
                fontWeight: 'bold',
                fontSize: '10px',
                padding: '8px 12px',
                border: '1px solid',
                marginRight: '10px',
              }}
              // link={url}
              // target="blank"
            >
              See event
            </Button>
            {/* <ShareButton onClick={() => console.log('share')}>
              <img alt="share" src={shareIcon} width="16" height="16" />
              Share
            </ShareButton> */}
          </div>
          {/* <Price>{price > 0 ? `€${price.toFixed(2)}` : 'FREE'}</Price> */}
        </Footer>
      </Container>
    </a>
  )
}

export default EventItem
