/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import device from '../../utils/mq'

import { BookIcon, SpeakerIcon, VideoIcon, FlagIcon, StarIcon } from './Icons'

const TabsWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f7f7ff;
  position: fixed;
  z-index: 22;
  padding: 15px 30px 15px 0px;
  top: 160px;
  @media (min-width: 740px) {
    background-color: none;
    position: static;
    z-index: 22;
    padding: 0;
    top: 0;
    width: 100%;
  }
`

const TabsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4vw;

  @media ${device.tablet} {
    gap: 0;
  }
`

const TabButton = styled(Link)`
  border: none;
  width: 25%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.12), 0px 4px 8px rgba(0, 8, 32, 0.08);
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 14px 10px 20px;

  background: linear-gradient(
      to left,
      white 49%,
      ${(props) => (props.color ? props.color : '#5a55ab')} 49%
    )
    right;
  background-size: 200%;
  transition: 0.15s ease-out;

  &:hover,
  .active {
    background-position: left;
    p,
    span,
    svg path {
      transition: 0.1s ease-out;
      color: ${({ showOnMobile }) => (showOnMobile ? 'black' : 'white')};
      fill: white;
    }
  }
  &.active {
    background-position: left;
    p,
    span,
    svg path {
      transition: 0.1s ease-out;
      color: white;
      fill: white;
    }
  }

  @media ${device.tablet} {
    justify-content: space-between;
    width: 25%;
    justify-content: space-between;
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`

const Text = styled.div`
  text-align: left;
  display: ${({ showOnMobile }) => (showOnMobile ? 'flex' : 'none')};
  width: ${({ showOnMobile }) => (showOnMobile ? '20vw' : 'auto')};
  justify-content: ${({ showOnMobile }) => (showOnMobile ? 'center' : 'auto')};
  p {
    margin: 0px;
    color: black;
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
  }
  span {
    font-size: 16px;
    color: #556685;
  }

  @media ${device.tablet} {
    display: ${({ showOnMobile }) => (showOnMobile ? 'none' : 'block')};
  }
`
const MobileInfo = styled.div`
  display: ${({ showOnMobile }) => (showOnMobile ? 'flex' : 'none')};
  text-align: center;
  margin-top: 5px;
  gap: 4vw;
  @media ${device.mobileL} {
    margin-top: 10px;
  }
  p {
    margin: 0px;
    color: black;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    @media ${device.mobileL} {
      font-size: 14px;
    }
  }
`

const colors = {
  0: `#5a55ab`,
  1: `#2fbbb0`,
  2: `#347EB6`,
  3: `#2FC373`,
  4: `#e89c36`,
}

const Tabs = ({
  // onTabChange,
  activeTab,
  data,
  events,
  LPRecommendations,
  interviews,
}) => {
  const getLength = (type) =>
    data
      ?.filter(({ type: t }) => type === t)
      ?.reduce((acc, curr) => acc + (curr?.items?.length || 0), 0) || 0

  const tabsList = [
    {
      title: 'Read',
      amount: `${getLength('READ')}`,
      icon: 'book',
      type: 'READ',
    },
    {
      title: 'Listen',
      amount: `${getLength('LISTEN')}`,
      icon: 'speaker',
      type: 'LISTEN',
    },
    {
      title: 'Interviews',
      amount: `${interviews.length}`,
      icon: 'video',
      type: 'INTERVIEWS',
    },
    {
      title: 'Events',
      amount: `${events.length}`,
      icon: 'star',
      type: 'EVENT',
    },
    {
      title: 'Paths',
      amount: LPRecommendations.length,
      icon: 'flag',
      type: 'LEARN',
    },
  ]

  function iconHandler(type) {
    return {
      book: <BookIcon fill="#BDBBDD" />,
      speaker: <SpeakerIcon fill="#9DE6E0" />,
      video: <VideoIcon fill="#DDECF8" />,
      star: <StarIcon fill="#D1F2E1" />,
      flag: <FlagIcon fill="#FEC97E" />,
    }[type]
  }

  // console.log('Active tab: ', activeTab)

  // useEffect(() => onTabChange(activeTab), [activeTab])

  return (
    <TabsWrapper>
      <TabsList>
        {tabsList.map((tab, index) => (
          <TabButton
            key={tab.title}
            // onClick={() => {
            //   onTabChange(tab.type)
            // }}
            to={`/virtual-library/${tab.title.toLowerCase()}`}
            color={colors[index]}
            className={activeTab === tab.type ? 'active' : ''}
          >
            <Text showOnMobile={false}>
              <p>{tab.title}</p>
              <span>{tab.amount}</span>
            </Text>
            {iconHandler(tab.icon)}
          </TabButton>
        ))}
      </TabsList>
      <MobileInfo showOnMobile>
        {tabsList.map((tab) => (
          <Text showOnMobile>
            <p>{tab.title}</p>
          </Text>
        ))}
      </MobileInfo>
    </TabsWrapper>
  )
}

export default Tabs
