/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 6px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  @media (min-width: 420px) {
    min-width: 160px;
  }
  @media (min-width: 1200px) {
    padding: 16px 11px 20px;
  }
`

const CardPicture = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CardImg = styled.img`
  border-radius: 50%;
  width: 65px;
  height: 65px;
  object-fit: contain;
`

const CardLabel = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 6px;
  justify-content: center;
  font-family: Poppins;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  background-color: ${({ isHost }) => (isHost ? '#5A55AB' : '#29A399')};
  position: relative;
  bottom: 16px;
  z-index: 3;
  width: fit-content;
`

const CardName = styled.p`
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  text-align: center;
  margin: 0px;
`

const CardTitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin: 4px 0px auto 0px;
`

const CardLogo = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 60px;
  margin-top: 24px;
  max-width: 106px;
  margin-inline: auto;
`

// eslint-disable-next-line react/prop-types
const ParticipantCard = ({ participant }) => (
  <Card>
    <CardPicture>
      <CardImg
        src={participant.participantPicture.file.url}
        alt={participant.fullName}
      />
      <CardLabel isHost={participant.host}>
        {participant.host ? 'Host' : 'Guest'}
      </CardLabel>
    </CardPicture>
    <CardName>{participant.fullName}</CardName>
    <CardTitle>{participant.titlerole}</CardTitle>
    <CardLogo
      src={participant.participantCompanyLogo.file.url}
      alt={participant.fullName}
    />
  </Card>
)

export default ParticipantCard
