/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import slugify from 'slugify'
import InterviewPathBanner from './InterviewPathBanner'
import { PlayCircleIcon, ChevronUpIcon, ChevronDownIcon } from '../Icons'

const TakeawaysParagraph = styled.p`
  margin: 0 0 10px 0 !important;
`

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    // [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <TakeawaysParagraph>{children}</TakeawaysParagraph>
    ),
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
    [INLINES.HYPERLINK]: (node) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {node.content[0].value}
      </a>
    ),
  },
}

const QuestionsWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const Question = styled.div`
  border-top: ${({ first }) => (first ? 'none' : '1px solid #d9e1ee')};
`

const QuestionTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 9px 9px 0px;
  padding: ${({ first }) => (first ? '0px 9px' : '9px 9px 9px 0px')};
  gap: 6px;
  p {
    font-family: Poppins;
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    margin: 0px;
    font-weight: 400;
  }
`

const QuestionTakeaways = styled.div`
  padding-bottom: 14px;
  p {
    font-family: Poppins;
    color: #556685;
    font-size: 14px;
    line-height: 22px;
    margin: 0px;
    font-weight: 400;
  }
`

const TitleIcon = styled.div`
  color: #556685;
  list-style: none;
  display: flex;
  align-items: center;
  margin-inline: auto 5px;
`

// const Question = (question) => (
//   <Details>
//     <Summary>
//       <PlayCircleIcon fill="#5A55AB" />
//       <p>{question.title}</p>
//       <ChevronUpIcon fill="#152540" />
//     </Summary>
//     <p>{question.takeaways}</p>
//   </Details>
// )

const BoldText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin: 0;
  }
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #5a55ab;
    background: #efeef7;
    border-radius: 100px;
    padding: 2px 8px;
  }
`

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IconContainer = styled(Link)`
  display: flex;
  align-items: flex-start;
  p {
    color: #556685;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0px 8px 0px 0px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`

const Chevron = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: ${({ left }) => (left ? 'rotate(270deg)' : 'rotate(90deg)')};
`

const ShortsWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 850px;
  @media (min-width: 1200px) {
    max-width: 840px;
  }
`

const TeaserImages = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 12px;
  flex-wrap: nowrap;
  justify-content: ${({ length }) => (length > 4 ? 'space-between' : 'normal')};
`

const TeaserImage = styled(Link)`
  border-radius: 4px;
  background-image: url(${(props) => props.teaser});
  background-size: 150px 253px;
  background-repeat: no-repeat;
  height: 253px;
  max-width: 140px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media (min-width: 768px) {
    max-width: 170px;
  }
  @media (min-width: 1200px) {
    /* max-width: 170px; */
  }
  p {
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    margin: 0px;
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
`

const BannerWrapperLargeScreen = styled.div``

const Shorts = ({ interview }) => {
  const safeWindow = typeof window !== 'undefined' ? window : {}

  const [current, setCurrent] = useState(0)
  const [currentImageIdx, setCurrentImagIdx] = useState(0)
  const [windowWidth, setWindowWidth] = useState(safeWindow.innerWidth || 0)

  const slides =
    interview.shorts.length === 1
      ? 1
      : windowWidth > 1200
      ? 4
      : windowWidth > 1000
      ? 5
      : windowWidth > 670
      ? 4
      : windowWidth > 470
      ? 3
      : 2

  useEffect(() => {
    if (typeof safeWindow.addEventListener === 'function') {
      const listener = (e) => {
        setWindowWidth(e.target.innerWidth)
      }

      safeWindow.addEventListener('resize', listener)

      return () => window.removeEventListener('resize', listener)
    }
    return () => {}
  }, [safeWindow, setWindowWidth])

  const prevSlide = () => {
    const resetToVeryBack = currentImageIdx === 0

    const index = resetToVeryBack
      ? interview.shorts.length - 1
      : currentImageIdx - 1

    setCurrentImagIdx(index)
  }

  const nextSlide = () => {
    const resetIndex = currentImageIdx === interview.shorts.length - 1

    const index = resetIndex ? 0 : currentImageIdx + 1

    setCurrentImagIdx(index)
  }

  const activeImageSourcesFromState = interview.shorts.slice(
    currentImageIdx,
    currentImageIdx + slides
  )

  const imageSourcesToDisplay =
    activeImageSourcesFromState.length < slides
      ? [
          ...activeImageSourcesFromState,
          ...interview.shorts.slice(
            0,
            slides - activeImageSourcesFromState.length
          ),
        ]
      : activeImageSourcesFromState

  const clickHandle = (i) => {
    // eslint-disable-next-line no-unused-expressions
    current === i ? setCurrent(null) : setCurrent(i)
  }

  return (
    <ShortsWrap>
      <TopInfo>
        <BoldText>
          <p>Interview Shorts</p>
          <span>{interview.shorts.length}</span>
        </BoldText>
        {slides < interview.shorts.length && (
          <Buttons>
            <Chevron onClick={prevSlide} left>
              <ChevronUpIcon fill="#152540" />
            </Chevron>
            <Chevron onClick={nextSlide} left={false}>
              <ChevronUpIcon fill="#152540" />
            </Chevron>
          </Buttons>
        )}
      </TopInfo>
      <TeaserImages length={interview.shorts.length}>
        {imageSourcesToDisplay.map((question) => (
          <TeaserImage
            key={question.order}
            teaser={question.teaserImage?.file?.url}
            to={`/virtual-library/shorts/${slugify(
              question.title
            ).toLowerCase()}`}
            slider
            length={imageSourcesToDisplay.length}
          >
            <PlayCircleIcon fill="#ffffff" />
            <p>{question.title}</p>
          </TeaserImage>
        ))}
      </TeaserImages>
      <BannerWrapperLargeScreen>
        <InterviewPathBanner
          large
          pictureUrl={interview.interviewLearningPathImage?.file?.url}
          title={interview.interviewLearningPath}
          description={
            interview.interviewLearningPathShortDescription
              .interviewLearningPathShortDescription
          }
        />
      </BannerWrapperLargeScreen>
      <BoldText>
        <p>Questions</p>
        <span>{interview.shorts.length}</span>
      </BoldText>
      <QuestionsWrap>
        {interview.shorts.map((question, i) => (
          <Question key={question.title} first={i === 0}>
            <QuestionTitle>
              <IconContainer
                to={`/virtual-library/shorts/${slugify(
                  question.title
                ).toLowerCase()}`}
                style={{ cursor: 'pointer' }}
              >
                <PlayCircleIcon fill="#5A55AB" />
              </IconContainer>
              <p>{question.title}</p>
              <TitleIcon
                onClick={() => {
                  clickHandle(i)
                }}
              >
                {current === i ? (
                  <ChevronUpIcon fill="#152540" />
                ) : (
                  <ChevronDownIcon fill="#152540" />
                )}
              </TitleIcon>
            </QuestionTitle>
            {current === i ? (
              <QuestionTakeaways>
                {question.takeawaysRich ? (
                  <div>{renderRichText(question.takeawaysRich, options)}</div>
                ) : (
                  <p>{question.takeaways.takeaways}</p>
                )}
              </QuestionTakeaways>
            ) : null}
          </Question>
        ))}
      </QuestionsWrap>
    </ShortsWrap>
  )
}

export default Shorts
