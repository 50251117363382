/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// import { graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import SEO from '../components/Seo'
import Filter from '../components/virtualLibrary/Filter'
import ContentsList from '../components/virtualLibrary/ContentsList'
import Tabs from '../components/virtualLibrary/Tabs'
import mq from '../utils/mq'
import MailchimpEmailSignup from '../components/MailchimpEmailSignup'
import FilterIcon from '../assets/icons/filter.svg'

const Wrapper = styled.div`
  background: #f7f7ff;
  width: 100%;
`

const VirtualLibraryWrapper = styled.div`
  max-width: 1300px;
  padding: 0px 14px 78px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 740px) {
    padding: 0px 32px 78px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;

  @media ${mq.tablet} {
    flex-direction: row;
    margin-top: 50px;
  }
`

// const ShowFiltersOnMobile = styled.div`
//   display: flex;

//   @media ${mq.tablet} {
//     display: none;
//   }
// `

// const HideFiltersOnMobile = styled.div`
//   display: none;

//   @media ${mq.tablet} {
//     display: flex;
//   }
// `

const expandFilters = keyframes`
   0% {
    transform: translateY(10rem);
    }
    100% {
    transform: translateY(0rem);
    }
`

const Filters = styled.div`
  display: none;
  position: fixed;
  overflow: hidden;
  width: 100vw;
  z-index: 23;
  padding-left: 5px;
  background: #f7f7ff;
  bottom: 85px;
  left: 9px;
  animation: ${expandFilters} 0.8s;
  ${(props) =>
    props.showFiltersOnMobile &&
    `
    display: flex;
`}

  @media (min-width: 740px) {
    left: 24px;
  }

  @media ${mq.tablet} {
    display: flex;
    position: relative;
    overflow: hidden;
    width: auto;
    z-index: 23;
    padding-left: 5px;
    background: #f7f7ff;
    bottom: 0;
    left: 0;
    animation: none;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  background: #f7f7ff;
  width: 100%;
  @media ${mq.tablet} {
    display: none;
  }
`

const FilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background: #2fbbb0;
  border-radius: 100px;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.08em;
  border: 1px solid #2fbbb0;
  margin: 26px 0 20px;
  width: 140px;

  & img {
    padding-right: 7px;
  }
`
const MailchimpWrapper = styled.div`
  @media screen and (max-width: 740px) {
    display: none;
  }
`


// const contentTextByType = {
//   READ: 'books',
//   WATCH: 'videos',
//   LISTEN: 'podcasts',
// }

const recommendedPaths = [
  '61b9d7d1ec31e00029eb6085',
  '617b07a497f84d0029a1bb71',
  '60994915f056790028c9345c',
  '6081b11f1804d50028c3c706',
  '6065eda0e9797400281038a4',
  '6080625b60fcf10029e6b486',
]

const trackTabView = (w, tab) => {
  if (typeof w.gtag === 'function') {
    w.gtag('event', 'page_view', {
      page_path: `${w.location.pathname}/${tab.toLowerCase()}`,
    })
  }
}

const VirtualLibrary = ({
  // data: {
  //   allContentfulVirtualLibraryContent: { nodes: items },
  // },
  pageContext: {
    items,
    learningPaths,
    events,
    interviews,
    initialTab,
    singleInterview,
    singleShort,
    interview,
  },
}) => {
  const filteredEvents = events.filter(({ exactDate, endDate }) => {
    // FILTER OUT OLD EVENTS
    if (!exactDate) return true

    const thresholdDate = endDate ? new Date(endDate) : new Date(exactDate)

    return thresholdDate > new Date()
  })

  const HRpaths = [
    ...learningPaths['Human Resources'],
    ...learningPaths['People Development'],
    // ...learningPaths.Onboarding,
  ]

  const LPRecommendations = HRpaths.filter(({ _id }) =>
    recommendedPaths.includes(_id)
  )

  LPRecommendations.sort(
    // eslint-disable-next-line no-underscore-dangle
    (a, b) => recommendedPaths.indexOf(a._id) - recommendedPaths.indexOf(b._id)
  )
  const safeWindow = typeof window !== 'undefined' ? window : {}

  // const params = new URLSearchParams(safeWindow?.location?.search)

  // const initialTab = 'READ' // params.get('tab') || 'READ'

  const [tab, setTab] = useState(initialTab)

  const [filter, setFilter] = useState({
    skills: 'Getting started',
    // community: null,
    price: { free: false, paid: false },
  })

  const [showFiltersOnMobile, setShowFiltersOnMobile] = useState(false)

  const handleClickOnFilterButton = () => {
    setShowFiltersOnMobile(!showFiltersOnMobile)
  }

  const priceHandler = () => {
    if (filter.price?.free === true && filter.price?.paid === false) {
      return 'free'
    }
    if (filter.price?.free === false && filter.price?.paid === true) {
      return 'paid'
    }
    return 'all'
  }

  const filteredCategories = items.map(({ items: i, ...rest }) => ({
    ...rest,
    items:
      i?.filter(
        (item) =>
          item.skills === filter.skills &&
          // item.community === filter.community &&
          ((priceHandler() === 'free' && item.price === 0) ||
            (priceHandler() === 'paid' && item.price > 0) ||
            (priceHandler() === 'all' && item.price >= 0))
      ) || [],
  }))

  filteredCategories.push({
    name: 'Upcoming events',
    type: 'EVENT',
    items: filteredEvents.sort(
      (a, b) => new Date(a.exactDate) - new Date(b.exactDate)
    ),
  })

  const data = filteredCategories
    .filter(({ type }) => type === tab)
    .filter(({ items: i }) => i.length !== 0)

  data.sort(
    (a, b) => b.topCategory - a.topCategory || !!b.community - !!a.community
  )

  const showFilter = tab === 'READ' || tab === 'LISTEN' || tab === 'INTERVIEWS'

  // const listItems = items.filter(
  //   (item) =>
  //     item.skills === filter.skills &&
  //     // item.community === filter.community &&
  //     ((priceHandler() === 'free' && item.price === 0) ||
  //       (priceHandler() === 'paid' && item.price > 0) ||
  //       (priceHandler() === 'all' && item.price >= 0))
  // )

  // const allCategories = [...new Set(listItems.map((item) => item.type))]

  // const data = allCategories.map((category) => {
  //   const categoryItems = listItems.filter((item) => item.type === category)

  //   return {
  //     title: `Top ${categoryItems.length} ${contentTextByType[category]} for ${filter.skills}`,
  //     type: category,
  //     items: categoryItems,
  //   }
  // })

  // if (tab === 'EVENT') {
  //   data.push({
  //     title: '',
  //     type: 'EVENT',
  //     items: events,
  //   })
  // }

  return (
    <>
      <SEO
        title="Innential - People development library"
        description="Top books, podcasts, events and more for all people development, L&D and HR topics."
        meta={[
          {
            property: 'og:image',
            content:
              'https://innential-production.s3.eu-central-1.amazonaws.com/website/logo-lndshakers.jpeg',
          },
          {
            property: 'og:url',
            content: 'https://innential.com/virtual-library',
          },
        ]}
      />
      <Wrapper>
        <VirtualLibraryWrapper>
          <MailchimpWrapper>
            <MailchimpEmailSignup />
          </MailchimpWrapper>

          <Tabs
            // onTabChange={() => {
            //   // setTab(value)
            //   // GOOGLE ANALYTICS TRACKING OF TAB VIEW
            //   // trackTabView(safeWindow, value)
            // }}
            LPRecommendations={LPRecommendations}
            data={items}
            activeTab={tab}
            events={filteredEvents}
            interviews={interviews}
          />
          <Container>
            {tab === 'INTERVIEWS' && (
              <ContentsList
                learningPaths={LPRecommendations}
                data={data}
                tab={tab}
                safeWindow={safeWindow}
                interviews={interviews}
                singleInterview={singleInterview}
                singleShort={singleShort}
                interview={interview}
              >
                <Filters showFiltersOnMobile={showFiltersOnMobile}>
                  <Filter
                    onFilterChange={(values) => setFilter(values)}
                    isInterview
                  />
                </Filters>
              </ContentsList>
            )}
            {!(tab === 'INTERVIEWS') && (
              <>
                {showFilter ? (
                  <>
                    <Filters showFiltersOnMobile={showFiltersOnMobile}>
                      <Filter onFilterChange={(values) => setFilter(values)} />
                    </Filters>

                    <ButtonWrapper>
                      <FilterButton onClick={handleClickOnFilterButton}>
                        <img src={FilterIcon} alt="filter" />{' '}
                        {showFiltersOnMobile ? 'Hide' : 'Filters'}
                      </FilterButton>
                    </ButtonWrapper>
                  </>
                ) : null}
                <ContentsList
                  learningPaths={LPRecommendations}
                  data={data}
                  tab={tab}
                  safeWindow={safeWindow}
                  interviews={interviews}
                />
              </>
            )}
          </Container>
        </VirtualLibraryWrapper>
      </Wrapper>
    </>
  )
}

export default VirtualLibrary

// export const query = graphql`
//   query {
//     allContentfulVirtualLibraryContent {
//       nodes {
//         id
//         type
//         author
//         price
//         skills
//         community
//         title
//         durationText
//         durationHours
//         description {
//           description
//         }
//         source {
//           id
//           name
//           icon {
//             gatsbyImageData
//           }
//         }
//         url
//         thumbnail {
//           file {
//             url
//           }
//         }
//       }
//     }
//   }
// `
