/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import InterviewItem from './InterviewItem'
import ParticipantCard from './ParticipantCard'
import Shorts from './Shorts'

const Container = styled.div`
  display: flex;
  gap: 36px;
  flex-direction: column;
  padding: 0px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: auto;
  width: 100%;
  /* @media (min-width: 768px) {
    flex-direction: row;
  } */
  @media (min-width: 1200px) {
    flex-direction: column;
    max-width: 400px;
  }
`

const BoldText = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin: 0;
  }
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #5a55ab;
    background: #efeef7;
    border-radius: 100px;
    padding: 2px 8px;
  }
`

const ParticipantsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  /* @media (min-width: 768px) {
    flex: 1;
  } */
`

const InterviewItemWrapper = styled.div`
  /* @media (min-width: 768px) {
    flex: 1;
  } */
`

const Participants = styled.div`
  display: flex;
  gap: 30px;
  flex: 1;
`

const InfoNoBanner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: auto;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    flex-direction: column;
    max-width: 400px;
  }
`

// eslint-disable-next-line react/prop-types
const Interview = ({ data }) => (
  <Container>
    <Info>
      <InfoNoBanner>
        <InterviewItemWrapper>
          <InterviewItem interview={data} isInterview />
        </InterviewItemWrapper>
        <ParticipantsWrapper>
          <BoldText>
            <p>Participants</p>
            <span>{data.participants.length}</span>
          </BoldText>

          <Participants>
            {data.participants.map((participant) => (
              <ParticipantCard key={participant.id} participant={participant} />
            ))}
          </Participants>
        </ParticipantsWrapper>
      </InfoNoBanner>
    </Info>
    <Shorts interview={data} />
  </Container>
)
export default Interview
