/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import Slider from 'rc-slider'
// import Button from '../Button'
import 'rc-slider/assets/index.css'
import mq from '../../utils/mq'

const FilterWrapper = styled.div`
  position: relative;
  width: 100%;

  @media ${mq.tablet} {
    width: 255px;
  }
`

const FilterSections = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 0;

  /* @media screen and (min-width: 500px) {
    flex-direction: row;
    justify-content: space-between;
  } */

  @media ${mq.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 40px;
  }
`

const FilterSection = styled.div`
  margin-bottom: 6px;
  min-width: 90px;
  display: ${(props) => (props.isInterview ? 'none' : 'block')};

  @media ${mq.tablet} {
    flex: 0 1 auto;
    width: 100%;
    margin-bottom: 24px;
  }
`

const SkillsItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const Title = styled.h2`
  margin: 0;
  padding: 8px 0;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  border-bottom: 1px solid #d9e1ee;

  @media ${mq.tablet} {
    flex-direction: column;
    flex-wrap: none;
    margin: 0 0 24px 0;
    padding: 0 0 8px;
  }
`

const SectionTitle = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  color: #556685;
  margin-top: 25px;
`

const SectionBody = styled.div`
  display: flex;
  flex-direction: row;
  @media ${mq.tablet} {
    flex-direction: column;
  }
`

const FilterItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  pointer-events: inherit;
  label {
    display: flex;
    font-size: 14px;
    color: #152540;
  }

  input[type='radio'] {
    display: none;
  }
  input[type='checkbox'] {
    display: none;
  }

  @media ${mq.mobileL} {
    margin-top: 16px;
    margin-right: 10px;
  }
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: ${(props) => (props.radio ? '50%' : '4px')};
  margin-right: 12px;
  border: 1px solid ${(props) => (props.checked ? '#5A55AB' : '#8494b2')};
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    border-radius: ${(props) => (props.radio ? '50%' : '2px')};
    width: ${(props) => (props.radio ? '8px' : '10px')};
    height: ${(props) => (props.radio ? '8px' : '10px')};
    position: absolute;
    display: block;
    background-color: ${(props) => (props.checked ? '#5A55AB' : 'transparent')};
  }
`

// const SliderWrapper = styled.div`
//   .rc-slider-rail {
//     background-color: #d9e1ee;
//     height: 8px;
//   }
//   .rc-slider-track {
//     background-color: #5a55ab;
//     height: 8px;
//   }
//   .rc-slider-handle {
//     width: 18px;
//     height: 18px;
//     border: solid 4px white;
//     background-color: #5a55ab;
//     box-shadow: 0px 1px 4px rgba(0, 8, 32, 0.1),
//       0px 32px 64px rgba(0, 8, 32, 0.12);
//   }
// `

// const SliderLabel = styled.div`
//   display: flex;
//   justify-content: space-between;
//   p {
//     font-family: Poppins;
//     font-size: 12px;
//     font-weight: normal;
//     color: #000000;
//   }
// `

// const ButtonsWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
// `

const skillsFilters = [
  { id: 'Getting started', title: 'Getting Started' },
  { id: 'People Analytics', title: 'People Analytics' },
  { id: 'Learning Culture', title: 'Culture' },
  // { id: 'Learning Experience', title: 'Learning Experience' },
  { id: 'Onboarding Experience', title: 'Onboarding Experience' },
  { id: 'Employee Success', title: 'Employee Success' },
  { id: 'Diversity & Inclusion', title: 'Diversity & Inclusion' },
  { id: 'Leadership Development', title: 'Leadership Development' },
  { id: 'Learning Experience Design', title: 'Learning Experience Design' },
  { id: 'First Time Leaders', title: 'First Time Leaders' },
  { id: 'Learning Policies', title: 'Learning Policies' },
]

const communityFilters = [
  { id: 'Innential', title: 'Innential' },
  { id: 'L&D Shakers', title: 'L&D Shakers' },
  // { id: 'BerLearn', title: 'BerLearn' },
  // { id: 'OffBeat', title: 'OffBeat' },
]

const Filter = ({ onFilterChange, isInterview }) => {
  const [skills, setSkills] = useState(skillsFilters[0].id)
  const [community, setCommunity] = useState(communityFilters[0].id)

  const [price, setPrice] = useState({ free: false, paid: false })

  const durationRange = { min: 30, max: 600 }
  const [duration, setDuration] = useState(durationRange.min)

  useEffect(() => onFilterChange({ skills, community, price, duration }), [
    skills,
    community,
    price,
    duration,
  ])

  useEffect(() => {
    if (sessionStorage.getItem('skills')) {
      const chosenSkills = sessionStorage.getItem('skills')
      setSkills(chosenSkills)
    }
    if (sessionStorage.getItem('price')) {
      const chosenPrice = JSON.parse(sessionStorage.getItem('price'))
      setPrice(chosenPrice)
    }
  }, [])

  const RadioInput = ({ id, title, category, onChange }) => {
    const checked = category === id
    return (
      <FilterItem>
        <input
          type="radio"
          checked={checked}
          id={id}
          value={id}
          onChange={onChange}
        />
        <label htmlFor={id}>
          <Icon checked={checked} radio>
            <span />
          </Icon>
          {title}
        </label>
      </FilterItem>
    )
  }

  const CheckInput = ({ id, title, category, onChange }) => (
    <FilterItem>
      <input type="checkbox" id={id} checked={category} onChange={onChange} />
      <label htmlFor={id}>
        <Icon checked={category}>
          <span />
        </Icon>
        {title}
      </label>
    </FilterItem>
  )

  return (
    <FilterWrapper>
      <Title>Filters</Title>

      <FilterSections>
        <FilterSection>
          <SectionTitle>Topics</SectionTitle>
          <SkillsItems>
            {skillsFilters.map((item) => (
              <RadioInput
                key={item.id}
                id={item.id}
                title={item.title}
                category={skills}
                onChange={(e) => {
                  setSkills(e.target.value)
                  sessionStorage.skills = e.target.value
                }}
              />
            ))}
          </SkillsItems>
        </FilterSection>

        {/* <FilterSection>
          <SectionTitle>Community</SectionTitle>
          <SectionBody>
            {communityFilters.map((item) => (
              <RadioInput
                key={item.id}
                id={item.id}
                title={item.title}
                category={community}
                onChange={(e) => setCommunity(e.target.value || null)}
              />
            ))}
          </SectionBody>
        </FilterSection> */}

        <FilterSection isInterview={isInterview}>
          <SectionTitle>Price</SectionTitle>
          <SectionBody>
            <CheckInput
              id="free"
              title="Free"
              category={price.free}
              onChange={(e) => {
                setPrice({ ...price, free: e.target.checked })
                sessionStorage.price = JSON.stringify({
                  ...price,
                  free: e.target.checked,
                })
              }}
            />
            <CheckInput
              id="paid"
              title="Paid"
              category={price.paid}
              onChange={(e) => {
                setPrice({ ...price, paid: e.target.checked })
                sessionStorage.price = JSON.stringify({
                  ...price,
                  paid: e.target.checked,
                })
              }}
            />
          </SectionBody>
        </FilterSection>

        {/* <SectionTitle>Duration</SectionTitle>
      <SectionBody>
        <SliderWrapper>
          <Slider
            value={duration}
            min={durationRange.min}
            max={durationRange.max}
            step={1}
            onChange={(value) => setDuration(value)}
          />
          <SliderLabel>
            <p>
              {duration <= 60 && `${duration} min`}
              {duration > 60 &&
                duration < durationRange.max &&
                `${Math.floor(duration / 60)} h ${duration % 60} min`}
              {duration === durationRange.max &&
                `${Math.floor(duration / 60)} h`}
            </p>
            <p>{durationRange.max / 60} h</p>
          </SliderLabel>
        </SliderWrapper>
      </SectionBody> */}

        {/* <ButtonsWrapper>
        <Button
          title="Apply Filters"
          link="/virtual-library/#"
          primary
          secondary={false}
          style={{ fontSize: '12px', padding: '8px 22px' }}
          onClick={submit}
        />
        <Button
          title="Clear All"
          // link="/virtual-library/#"
          primary={false}
          secondary
          style={{ fontSize: '12px', padding: '8px 22px' }}
          onClick={clearAll}
        />
      </ButtonsWrapper> */}
      </FilterSections>
    </FilterWrapper>
  )
}

export default Filter
